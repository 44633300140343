import { Modal as _Modal, ModalOverlay } from "@ui/components/overlay/modal/Modal";
import { useThemeMediaQuery } from "@ui/hooks/useThemeMediaQuery";

const Modal = ({
    onClose,
    closeOnOverlayClick = true,
    size = null,
    children,
    initialFocusRef = null,
}) => {
    const { sm } = useThemeMediaQuery();
    const animationDirection = sm ? "slideInRight" : "slideInBottom";

    return (
        <_Modal
            isOpen={true}
            onClose={onClose}
            closeOnOverlayClick={closeOnOverlayClick}
            motionPreset={animationDirection}
            size={size}
            initialFocusRef={initialFocusRef}
        >
            <ModalOverlay />
            {children}
        </_Modal>
    );
};

export default Modal;
