const ShoppingBag = {
    title: "shoppingBag.title",
    itemsLimit: "shoppingBag.itemsLimit",
    emptyBagText: "shoppingBag.emptyBag.text",
    summaryTitle: "shoppingBag.summary.title",
    removeProduct: "shoppingBag.product.remove",
    button: {
        goToBackShopping: "shoppingBag.button.goToBackShopping",
        goToCheckout: "shoppingBag.button.goToCheckout",
    },
};

export default ShoppingBag;
