const Checkout = {
    shoppingBagSummaryTitle: "checkout.shoppingBag.summary.title",
    shoppingBagTitle: "checkout.shoppingBag.title",
    requiredFieldError: "checkout.error.requiredField",
    emptyBagText: "checkout.emptyBagText",
    headline: "checkout.ariaheadline",
    toggleNewsLetter: "checkout.ariaToggleNewsletter",
    step1: {
        title: "checkout.step1.title",
        formEmail: {
            title: "checkout.step1.formEmail.title",
            error: "checkout.step1.formEmail.error",
        },
        formCountry: {
            title: "checkout.step1.formCountry.title",
        },
        formPhone: {
            title: "checkout.step1.formPhone.title",
            reasonForInsert: "checkout.step1.formPhone.reasonForInsert",
            error: "checkout.step1.formPhone.error",
        },
        button: {
            title: "checkout.step1.button.continueToDelivery",
        },
        policy: {
            error: "checkout.step1.policy.error",
        },
        contactDetails: {
            error: "checkout.step1.contactDetails.error",
        },
        delivery: {
            error: "checkout.step1.delivery.error",
        },
    },
    step2: {
        title: "checkout.step2.title",
        deliveryMethod: "checkout.step2.deliveryMethod.title",
        shippingAddress: "checkout.step2.shippingAddress.title",
        billingAddress: "checkout.step2.billingAddress.title",
        formAddress: {
            title: "checkout.step2.formAddress",
            error: "checkout.step2.formAddress.error",
        },
        formAddressLine2: "checkout.step2.formAddressLine2",
        addAddressLine: "checkout.step2.addAddressLine",
        addressesMatch: "checkout.step2.addressesMatch",
        formFirstName: {
            title: "checkout.step2.formFirstName",
            error: "checkout.step2.formFirstName.error",
        },
        formLastName: {
            title: "checkout.step2.formLastName",
            error: "checkout.step2.formLastName.error",
        },
        formCity: {
            title: "checkout.step2.formCity",
            containsNumbersError: "checkout.step2.formCity.containsNumbers",
        },
        formZipCode: {
            title: "checkout.step2.formZipCode.title",
            formateError: "checkout.step2.formZipCode.formate",
            validError: "checkout.step2.formZipCode.valid",
        },
        formCountry: {
            title: "checkout.step2.formCountry.title",
            error: "checkout.step2.formCountry.error",
        },
        button: {
            title: "checkout.step2.button.continueToPayment",
        },
    },
    step3: {
        title: "checkout.step3.title",
        spinnerTitle: "checkout.step3.spinner.title",
        spinnerSubtitle: "checkout.step3.spinner.subtitle",
        orderCreation: "checkout.step3.orderCreation",
        dropinComponent: "checkout.step3.dropinComponent",
        dropinError: "checkout.step3.dropin.error",
        dropinSessionError: "checkout.step3.dropin.sessionError",
        zeroAmountMessage: "checkout.step3.dropin.zeroAmountMessage",
        zeroAmountButton: "checkout.step3.dropin.zeroAmountButton",
        zeroAmountErrorMessage: "checkout.step3.dropin.zeroAmountErrorMessage",
        zeroAmountTotal: "checkout.step3.dropin.zeroAmountTotal",
    },
};

export default Checkout;
