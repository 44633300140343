import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { CommonCMS } from "@lib/constants/contentful";

const AccessibilityFeedback = () => {
    const { get: getMicrocopy } = useMicrocopy();
    const accessibilityFeedbackMicrocopy = getMicrocopy(
        CommonCMS.global,
        CommonCMS.accessibilityFeedback
    );
    return (
        <a
            data-ae-client-feedback-link="true"
            href="#"
            style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
            {accessibilityFeedbackMicrocopy}
        </a>
    );
};
export default AccessibilityFeedback;
